/**
 * Shorten console.warn()
 */
function cw() {
    if (arguments.length > 0) {
        console.warn(...arguments);
    } else {
        console.warn('You forgot argument for console.warn');
    }
}

/**
 * Shorten console.log()
 */
function cl() {
    if (arguments.length > 0) {
        console.log(...arguments);
    }
}
/**
 * Shorten console.error()
 */
function ce() {
    if (arguments.length > 0) {
        console.error(...arguments);
    }
}

window.cw = cw;
window.ce = ce;
window.cl = cl;